.container::after {
  content: "";
  background: url(/images/login-shape.svg) no-repeat center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

.login-title::after {
  @apply bg-primary-500;
  position: absolute;
  bottom: -10px;
  left: 0;
  content: "";
  width: 55px;
  height: 3px;
}
